<template>
  <div>
    <!--    <component-->
    <!--      :is="mode"-->
    <!--      :button="button"-->
    <!--      @resend="resendCode($event)"-->
    <!--      @registration="otpCheck($event)"-->
    <!--    ></component>-->
    <div v-if="mode === 'TeletalkCampaign'">
      <div class="logo-box">
        <img src="images/logo.svg" />
      </div>

      <h1>প্রতিদিন ৫০টি কয়েন ফ্রি!! একই সাথে পছন্দের অসংখ্য গেম…</h1>

      <div id="ttimagebox"></div>

      <h2>
        মাত্র ২ টাকায় ৭ দিনের জন্য সাবস্ক্রাইব করুন, এবং খেলতে থাকুন আনলিমিটেড
        ইনস্ট্যান্ট গেমস।
      </h2>

      <h3>কোন ডাউনলোড প্রয়োজন নেই</h3>

      <div id="subscribe-btn">
        <button
          id="btn"
          onclick="showModal('regModal')"
          href="JavaScript:void(0);"
        >
          সাবস্ক্রাইব করুন
        </button>
      </div>

      <h5>*অফারটি শুধুমাত্র টেলিটক সাবস্ক্রাইবারদের জন্য</h5>
    </div>
    <div class="profile-wrap-landing" v-else>
      <div class="otp-content-landing">
        <p class="common-heading-landing">
          আপনার মোবাইল এ একটি ওটিপি পাঠানো হয়েছে, অনুগ্রহপূর্বক সেটা নিচের বক্স
          এ লিখুন
        </p>
        <br />
        <button
          class="bold-text"
          disabled="true"
          style="text-decoration: none;"
        >
          {{ otpAttemptRemaining }} Attempt Remaining
        </button>
      </div>
      <form action="#">
        <div class="form-group-landing otp-input-landing">
          <div class="otp-box">
            <input
              type="number"
              id="codeBox1"
              class="form-control-landing inputBox"
              maxlength="1"
              @keyup="onKeyUpEvent(1)"
              @change="onKeyUpEvent(1)"
              @focus="onFocusEvent(1)"
              v-model="otp1"
            />
          </div>

          <div class="otp-box">
            <input
              type="number"
              id="codeBox2"
              class="form-control-landing inputBox"
              maxlength="1"
              @keyup="onKeyUpEvent(2)"
              @change="onKeyUpEvent(2)"
              @focus="onFocusEvent(2)"
              v-model="otp2"
            />
          </div>

          <div class="otp-box">
            <input
              type="number"
              id="codeBox3"
              class="form-control-landing inputBox"
              maxlength="1"
              @keyup="onKeyUpEvent(3)"
              @change="onKeyUpEvent(3)"
              @focus="onFocusEvent(3)"
              v-model="otp3"
            />
          </div>

          <div class="otp-box">
            <input
              type="number"
              id="codeBox4"
              class="form-control-landing inputBox"
              maxlength="1"
              @keyup="onKeyUpEvent(4)"
              @change="onKeyUpEvent(4)"
              @focus="onFocusEvent(4)"
              v-model="otp4"
            />
          </div>
        </div>
        <div class="otp-link-landing">
          <button
            class="bold-text"
            type="button"
            @click="resendCode"
            v-if="button"
          >
            রিসেন্ড ওটিপি কোড
          </button>
        </div>
        <button
          type="button"
          class="btn-1-landing"
          @click="otpCheck()"
          :disabled="disabled"
          v-if="button"
        >
          {{ buttonText }}
        </button>
        <div class="subscribe-btn" v-else>
          <p class="btn">{{ buttonText }}</p>
        </div>
      </form>
    </div>
    <div class="modal-background-landing" id="regModal">
      <div class="modal-content-landing">
        <span class="close-landing" onclick="hideModal('regModal')"></span>
        <form class="subscription-frm-landing">
          <h5>আপনার টেলিটক নাম্বারটি নিচের বক্স এ লিখুন</h5>
          <div class="form-group-landing">
            <input
              class="form-control-1-landing"
              type="number"
              maxlength="11"
              placeholder="015XXXXXXXX"
              v-model="regristrationPhone"
            />
          </div>

          <div class="subscription-submit-btn-home-landing">
            <button
              class="common-btn-landing"
              type="button"
              @click="registration"
              v-if="button"
            >
              সাবমিট
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-background" id="chargingError">
      <div class="modal-content">
        <span class="close" onclick="hideChargingError('chargingError')"></span>
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const publicIp = require("public-ip");
// import TeletalkCampaign from "@/components/TeletalkCampaign.vue";
// import TeletalkOtp from "@/components/TeletalkOtp.vue";

export default {
  name: "Streamer",
  components: {
    // TeletalkCampaign,
    // TeletalkOtp
  },
  data: () => {
    return {
      host: null,
      error: false,
      //resendStatus: false,
      registrationStatus: true,
      resendOtp: false,
      message: null,
      regristrationPhone: "",
      button: true,
      mode: "TeletalkCampaign",
      individualTransactionId: 0,
      otpAttemptRemaining: 5,
      disabled: true,
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      ip: null,
      buttonText: "সাবমিট"
    };
  },
  async created() {
    try {
      this.$root.mainWrapper = "wrapper-main-landing";
      let src =
        this.$route.query["src"] !== undefined ? this.$route.query.src : "";
      this.host = this.$root.url();
      this.ip = await publicIp.v4();
      console.log("ip", this.ip);
      const path = "campaign";
      const url = `${this.host}/${path}?ip=${this.ip}&opt=4&src=${src}`;
      await axios.get(url);
      return true;
    } catch (e) {
      return false;
    }
  },
  methods: {
    async registration() {
      try {
        this.button = false;
        let phone = this.regristrationPhone;
        let name = "XYZ";
        let password = "unknown";
        let confirmPassword = "unknown";
        let operator = 4;
        if (phone !== "") {
          this.individualTransactionId = this.referenceId(phone);
          const path = "userRegistration";
          const url = `${this.host}/${path}?name=${name}&phone=${phone}&password=${password}&password_confirmation=${confirmPassword}&payment=${operator}&ip=${this.ip}&opt=4&individualTransactionId=${this.individualTransactionId}&type=promotional`;
          console.log(url);
          let response = await axios.get(url);
          if (response.data.success === true) {
            this.mode = "TeletalkOtp";
            this.otpAttemptRemaining = response.data.promotionalSmsCount;
            this.$root.mainWrapper = "container-landing";
            let errorObject = { status: false, message: null };
            this.errorMessage(errorObject);
            this.hideModal("regModal");
            this.button = true;
            return true;
          } else {
            let errorObject = { status: true, message: response.data.messages };
            this.errorMessage(errorObject);
            this.button = true;
            this.hideModal("regModal");
            this.showModal("chargingError");
            return true;
          }
        } else {
          let errorObject = {
            status: true,
            message: "please input phone number"
          };
          this.errorMessage(errorObject);
          this.button = true;
          this.hideModal("regModal");
          this.showModal("chargingError");
          return true;
        }
      } catch (e) {
        let errorObject = {
          status: true,
          message: "Something goes to wrong,please try again later"
        };
        this.errorMessage(errorObject);
        this.button = true;
        this.hideModal("regModal");
        this.showModal("chargingError");
        return true;
      }
    },
    async resendCode() {
      try {
        this.button = false;
        let phone = this.regristrationPhone;
        if (phone !== "") {
          const path = "forgotPassword";
          //const type = "registration";
          const url = `${this.host}/${path}?phone=${phone}&type=promotional`;
          let response = await axios.get(url);
          if (response.data.success === true) {
            this.otpAttemptRemaining = response.data.promotionalSmsCount;
            //this.resendStatus = true;
            let errorObject = {
              status: false,
              message: "successfully send otp"
            };
            this.errorMessage(errorObject);
            this.button = true;
            this.showModal("chargingError");
            return true;
          } else {
            let errorObject = { status: true, message: response.data.messages };
            this.errorMessage(errorObject);
            this.button = true;
            this.showModal("chargingError");
            return true;
          }
        } else {
          let errorObject = {
            status: true,
            message: "please input phone number"
          };
          this.errorMessage(errorObject);
          this.button = true;
          this.showModal("chargingError");
          return true;
        }
      } catch (e) {
        let errorObject = {
          status: true,
          message: "Something goes to wrong,please try again later"
        };
        this.errorMessage(errorObject);
        this.button = true;
        this.showModal("chargingError");
        return true;
      }
    },
    async otpCheck() {
      try {
        this.buttonText = "অনুগ্রহপূর্বক অপেক্ষা করুন";
        let vm = this;
        this.button = false;
        //let otp1 = this.otp1;
        let otp1 = this.otp1;
        let otp2 = this.otp2;
        let otp3 = this.otp3;
        let otp4 = this.otp4;
        let otp = `${otp1}${otp2}${otp3}${otp4}`;
        let length = otp.length;
        console.log("otp = ", otp, "length = ", length, "type = ", typeof otp);
        let registrationStatus = true;
        if (length == 4) {
          registrationStatus = true;
        } else {
          registrationStatus = false;
        }
        if (!registrationStatus) {
          let message =
            length < 4
              ? "Please fill up OTP field correctly"
              : "OTP length must be four character";
          let errorObject = {
            status: true,
            message: message
          };
          this.errorMessage(errorObject);
          this.showModal("chargingError");
          this.button = true;
          this.disabled = true;
          return true;
        }
        let phone = this.regristrationPhone;
        let name = "XYZ";
        let password = "unknown";
        let payment = 4;
        if (otp !== "" && phone !== "") {
          const path = "otp";
          const url = `${this.host}/${path}?name=${name}&phone=${phone}&password=${password}&otp=${otp}&payment=${payment}&individualTransactionId=${this.individualTransactionId}&type=promotional`;
          let response = await axios.get(url);
          if (
            response.data.success === true &&
            response.data.chargingStatus === true
          ) {
            localStorage.clear();
            localStorage.setItem("phone", response.data.phone);
            this.$root.mainWrapper = "wrapper-main";
            this.$router.push({ name: "Home" });
          } else if (
            response.data.success === true &&
            response.data.chargingStatus === false
          ) {
            let errorObject = {
              status: false,
              message: "Charging failed,please try again later"
            };
            this.errorMessage(errorObject);
            this.showModal("chargingError");
            localStorage.clear();
            localStorage.setItem("phone", response.data.phone);
            setTimeout(function() {
              vm.$router.push({ name: "Home" });
              vm.$root.mainWrapper = "wrapper-main";
            }, 4000);
            return true;
          } else {
            let errorObject = { status: true, message: response.data.messages };
            this.errorMessage(errorObject);
            this.button = true;
            this.disabled = true;
            this.showModal("chargingError");
            return true;
          }
        } else {
          let errorObject = {
            status: true,
            message: "Something wrong,try again later"
          };
          this.errorMessage(errorObject);
          this.button = true;
          this.disabled = true;
          this.showModal("chargingError");
          return true;
        }
      } catch (e) {
        let errorObject = {
          status: true,
          message: "Something goes to wrong,please try again later"
        };
        this.errorMessage(errorObject);
        this.button = true;
        this.disabled = true;
        this.showModal("chargingError");
        return true;
      }
    },
    errorMessage(error) {
      this.error = error.status;
      this.message = error.message;
    },
    hideModal(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    },
    showModal(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index) {
      let element = this.getCodeBoxElement(index);
      let length = element.value.length;
      let value = element.value;
      //console.log('index',index,'length',length, 'value',value);
      if (length > 1) {
        if (index === 4) {
          this.getCodeBoxElement(index).value = value[0];
          this.otp4 = value[0];
        } else {
          let i = 0;
          while (i < length) {
            //console.log('i = ',value[i]);
            if (index + i > 4) {
              break;
            }
            this.getCodeBoxElement(index + i).value = value[i];
            if (index + i === 1) {
              this.otp1 = value[i];
            } else if (index + i === 2) {
              this.otp2 = value[i];
            } else if (index + i === 3) {
              this.otp3 = value[i];
            } else if (index + i === 4) {
              this.otp4 = value[i];
            }
            i++;
          }
        }
      }
      let newIndex = index + length;
      //console.log('newIndex = ',newIndex);
      if (newIndex <= 4) {
        this.getCodeBoxElement(newIndex).focus();
      } else {
        this.getCodeBoxElement(index).blur();
      }
    },
    onFocusEvent(index) {
      for (let item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    buttonChecker(otp1, otp2, otp3, otp4) {
      // otp1 = parseInt(otp1);
      // otp2 = parseInt(otp2);
      // otp3 = parseInt(otp3);
      // otp4 = parseInt(otp4);
      if (otp1 != "" && otp2 != "" && otp3 != "" && otp4 != "") {
        return false;
      } else {
        return true;
      }
    },
    referenceId(phone) {
      let curTime = Date.now();
      let randomNumber = Math.floor(100000000 + Math.random() * 900000000);
      return curTime + phone + randomNumber;
    }
  },
  watch: {
    otp1: function(val) {
      this.disabled = this.buttonChecker(val, this.otp2, this.otp3, this.otp4);
    },
    otp2: function(val) {
      this.disabled = this.buttonChecker(this.otp1, val, this.otp3, this.otp4);
    },
    otp3: function(val) {
      this.disabled = this.buttonChecker(this.otp1, this.otp2, val, this.otp4);
    },
    otp4: function(val) {
      this.disabled = this.buttonChecker(this.otp1, this.otp2, this.otp3, val);
    }
  }
};
</script>

<style scoped></style>
